<template>
  <no-sidebar-layout>
    <v-container class="px-6 px-sm-0 px-xl-9 pt-0 pb-6">
      <v-row class="my-0" justify="center">
        <v-col cols="12" class="py-0">
          <div v-if="isDataLoaded">
            <div v-if="purchase">
              <div v-if="purchase.shipmentStatus === 'delivered'">
                <status :title="$t('user.purchase.deliveredSuccess')" type="success" />
              </div>
              <div v-else>
                <h2 class="mb-5" v-text="'Productos'" />
                <div v-for="(product, index) in purchase.cartProducts" :key="product.id">
                  <div v-if="product.size">
                    <h3 class="mb-5" v-text="`${index + 1}) ${product.name}`" />
                    <div class="d-flex justify-start">
                      <p class="mr-2" v-text="`${$t('common.size')}:`" /><p v-text="product.size" />
                    </div>
                  </div>
                </div>
                <v-text-field
                  v-model="form.pin" :label="$t('user.purchase.pin')" :error-messages="getFieldErrors('pin')"
                  outlined dense @blur="$v.form.pin.$touch()"
                />
                <v-btn color="primary" class="mr-3" dark depressed @click="markProductAsDelivered()" v-text="$t('user.purchase.deliver')" />
              </div>
            </div>
            <div v-else>
              <status :title="$t('common.anErrorHasOcurred')" :body="$t('user.purchase.purchaseNotExist')" type="error" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </no-sidebar-layout>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'PurchaseDelivered',
  components: {
    NoSidebarLayout: () => import('@/components/NoSidebarLayout'),
    Status: () => import('@/components/Status'),
  },
  mixins: [formUtils],
  props: { purchaseId: { type: String, required: true } },
  data: () => ({ isDataLoaded: false, purchase: null, form: { pin: null } }),
  validations: { form: { pin: { required, validPin: value => value === '1386' } } },
  async created() {
    const { purchaseId } = this
    this.purchase = await this.$store.dispatch('user/readPurchase', { purchaseId })
    this.isDataLoaded = true
  },
  methods: {
    async markProductAsDelivered() {
      const { purchaseId } = this
      if (!this.isFormValid()) return
      this.runAsync(async () => {
        const success = await this.$store.dispatch('user/markPurchaseAsDelivered', { purchaseId })
        if (success) {
          this.purchase.shipmentStatus = 'delivered'
        }
      })
    },
  },
}
</script>
